export const footerLinks = [
  {
    id: 'events',
    title: 'events',
    link: null,
    children: [
      {
        id: 'saved',
        title: 'saved',
        link: { name: 'favourites.favourites' },
        action: 'link'
      },
      {
        id: 'lastEvents',
        title: 'past',
        link:  { name: 'favourites.favourites' },
        action: 'link'
      }
    ]
  },
  {
    id: 'contact',
    title: 'contact',
    link: null,
    children: [
      {
        id: 'publish',
        title: 'offer',
        link: null,
        action: 'button'
      }
    ]
  },
  {
    id: 'socials',
    title: 'socials',
    link: null,
    children: [
      {
        id: 'instagram',
        title: 'instagram',
        link: 'https://www.instagram.com/happenin.app.pl/',
        action: 'link'
      },
      {
        id: 'facebook',
        title: 'facebook',
        link: 'https://www.facebook.com/profile.php?id=61557312775359',
        action: 'link'
      },
      {
        id: 'tiktok',
        title: 'tiktok',
        link: 'https://www.tiktok.com/@happenin.app.pl',
        action: 'link'
      }
    ]
  },
  {
    id: 'terms-and-privacy',
    title: 'terms-and-privacy',
    link: null,
    children: [
      {
        id: 'privacy-policy',
        title: 'privacy-policy',
        link: 'https://happenin.app/privacy_policy.pdf',
        action: 'link'
      }
    ]
  }
]