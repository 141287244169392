
import { Component, Inject, Vue } from 'vue-property-decorator'

import { AnyObject } from '@/core/general'
import { IModal, ModalServiceType } from '@/core/services/modal'
import { IDrawer, DrawerServiceType } from '@/core/services/drawer'

import { RootModuleDrawersCollection, RootModuleModalsCollection } from '@/modules/root/config'

import { footerLinks } from './Footer.config'
import { IRootService, RootServiceType } from '@/modules/root/services/root'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Footer' })
export class Footer extends Vue {
  @Inject({ from: 'isMobile', default: false })
  public isMobile!: () => boolean

  protected readonly modalService = this.$container.get<IModal>(ModalServiceType)
  protected readonly drawerService = this.$container.get<IDrawer>(DrawerServiceType)
  protected readonly rootService = this.$container.get<IRootService>(RootServiceType)

  public get footerLinks (): AnyObject {
    return footerLinks.map((link) => {
      return {
        ...link,
        title: this.$t(`footer.${link.title}.title`),
        children: link.children.map((child) => {
          return {
            ...child,
            title: this.$t(`footer.${link.title}.children.${child.title}`)
          }
        })
      }
    })
  }

  public openAuth (provider: 'google' | 'facebook'): void {
    if (provider === 'google') {
      window.open(this.rootService.getProperty('google_auth_start_url'), '_blank')
    } else {
      window.open(this.rootService.getProperty('facebook_auth_start_url'), '_blank')
    }
  }

  public childClick (childId: string): void {
    switch (childId) {
      case 'publish':
        this.openPublishEventDialog()
        break
    }
  }

  protected openPublishEventDialog (): void {
    if (this.isMobile()) {
      this.drawerService.show(RootModuleDrawersCollection.ContactUs)
    } else {
      this.modalService.show(RootModuleModalsCollection.ContactUs)
    }
  }
}
export default Footer
